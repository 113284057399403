<script>
import Chart from "@/components/widgets/chart";
import { api } from "@/state/services";

export default {
  components: { Chart },
  data() {
    return {
      empty: true,

      resume: {
        personal: 0,
        team: 0,
        total: 0,
      },

      chartdata: {},
      options: {}
    }
  },
  methods: {
    getChart() {
      api
        .get('chart/orders')
        .then(response => {
          if (response.data.status == 'success') {
            this.resume = response.data.chart.resume;

            this.chartdata = {
              labels: response.data.chart.labels,
              datasets: [
                {
                  label: 'Total',
                  backgroundColor: '#00BB00',
                  data: response.data.chart.data,
                },
              ]
            }

            this.options = {
              defaultFontColor: '#00BB00',
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      maxTicksLimit: 10,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      max: response.data.chart.max,
                      min: 0,
                      stepSize: response.data.chart.step,
                    },
                  },
                ],
              },
            }

            this.empty = false
          } else {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getChart();
  },
}
</script>

<template>
  <div class="w-100">
    <div class="d-flex flex-wrap flex-gap-10 mb-4">
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ resume.personal | currency }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Pessoal</div>
          </div>
        </div>
      </div>
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ resume.team | currency }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Equipe</div>
          </div>
        </div>
      </div>
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ resume.total | currency }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Total</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="empty"></div>
    <div v-else>
      <Chart :chartdata="chartdata" :options="options"></Chart>
    </div>
  </div>
</template>